// src/components/Hero/Hero.js

import React, { useState } from 'react';
import {
    Box,
    Typography,
    Grid,
    Container,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Link,
} from '@mui/material';


const Hero = () => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <section id="home-section">
            <Box
                component="section"
                sx={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/dot-dot-wave-shape.svg)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'bottom center',
                    minHeight: '30vh',
                    display: 'flex',
                    alignItems: 'stretch',
                    py: 10,
                    mb: 0,
                }}
            >
                <Container maxWidth="xl" sx={{height: '100%', p: 0}}>
                    <Grid
                        container
                        alignItems="stretch"
                        spacing={4}
                        sx={{height: '100%'}}
                    >
                        {/* Left Column */}
                        <Grid item xs={12} md={5} sx={{display: 'flex'}}>
                            <Box
                                sx={{
                                    textAlign: {xs: 'center', md: 'left'},
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    height: '100%',
                                }}
                                data-aos="fade-right"
                            >
                                {/* Migration Register Title */}
                                <Typography
                                    variant="h1"
                                    component="h1"
                                    gutterBottom
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: {xs: '2.5rem', md: '4rem'},
                                        lineHeight: 1.2,
                                        color: 'text.primary',
                                    }}
                                >
                                    Migration Register
                                </Typography>
                                {/* Subheading */}
                                <Typography
                                    variant="h4"
                                    component="p"
                                    gutterBottom
                                    sx={{
                                        fontSize: {xs: '1.5rem', md: '2.5rem'},
                                        color: 'text.secondary',
                                        mb: 3,
                                    }}
                                >
                                    A Workflow for Australia’s Immigration Cases
                                </Typography>
                                {/* Description */}
                                <Typography
                                    variant="body1"
                                    sx={{
                                        mt: 2,
                                        fontSize: {xs: '1rem', md: '1.125rem'},
                                        color: 'text.primary',
                                        maxWidth: {md: '80%'},
                                        margin: {xs: '0 auto', md: '0'},
                                    }}
                                >
                                    To help Migration Agents and Lawyers to manage visa cases more efficiently, improve
                                    collaboration, and fix disorganized processes when working with staff, partners, and
                                    clients.
                                </Typography>
                                {/* Request Demo Button */}
                                <Box sx={{mt: 4}}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleClickOpen}
                                        sx={{
                                            px: {xs: 3, md: 4},
                                            py: {xs: 1.2, md: 1.5},
                                            borderRadius: 2,
                                            fontSize: {xs: '0.9rem', md: '1rem'},
                                        }}
                                    >
                                        Request for Demo
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                        {/* Right Column */}
                        <Grid item xs={12} md={7} sx={{display: 'flex'}}>
                            <Box
                                sx={{
                                    position: 'relative',
                                    width: '100%',
                                    height: {xs: '200px', sm: '300px', md: '100%'},
                                    overflow: 'hidden',
                                    borderRadius: 2,
                                }}
                                data-aos="fade-left"
                            >
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/migration-register-data-collection.png`}
                                    alt="Migration Register Data Collection Process"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain',
                                        display: 'block',
                                    }}
                                    loading="lazy"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>

                {/* Demo Request Dialog */}
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="demo-request-dialog-title"
                    aria-describedby="demo-request-dialog-description"
                >
                    <DialogTitle id="demo-request-dialog-title">
                        Request a Migration Register Demo
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="demo-request-dialog-description">
                            <Typography variant="body1" gutterBottom>
                                Migration Register is currently under development and available on a request basis. To
                                understand its features and see a live demo, please connect with us:
                            </Typography>
                            {/*<Typography variant="body2" gutterBottom>*/}
                            {/*    <strong>Saumya Sachan:</strong>{' '}*/}
                            {/*    <Link href="mailto:saumya@getpostlabs.io" underline="hover" color="primary">*/}
                            {/*        saumya@getpostlabs.io*/}
                            {/*    </Link>*/}
                            {/*</Typography>*/}
                            <Typography variant="body2" gutterBottom>
                                <strong>Sumit Arora:</strong>{' '}
                                <Link href="mailto:sumit@getpostlabs.io" underline="hover" color="primary">
                                    sumit@getpostlabs.io
                                </Link>
                            </Typography>
                            <Typography variant="body2" gutterBottom sx={{mt: 2}}>
                                <strong>Location:</strong><br/>
                                GetPost Labs Pty Ltd<br/>
                                8 Clunies Ross Ct,<br/>
                                Brisbane Technology Park,<br/>
                                Queensland 4113
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </section>
            );
            };

            export default Hero;
