import React, { useRef } from 'react';
import Slider from 'react-slick';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Styled component for the slider
const StyledSlider = styled(Slider)`
    .slick-slide {
        display: flex;
        justify-content: center;
    }
    .slick-prev,
    .slick-next {
        z-index: 1;
    }
    .slick-dots li {
        margin: 0;
    }
    .slick-dots li button:before {
        font-size: 14px;
        color: #333;
        opacity: 1;
    }
`;

// Array of image paths
const images = [
    require('./assets/Migration_Register_Architecture.jpg'),
    require('./assets/Slide2.JPG'),
    require('./assets/Slide1.JPG'),
    require('./assets/Slide3.JPG'),
    require('./assets/Migration_Register_TeamChat.jpg'),
];

const MigrationRegisterSlides = () => {
    const sliderRef = useRef(null);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        dots: false,
        arrows: true,
        appendDots: dots => (
            <div>
                <ul style={{ display: 'flex', justifyContent: 'center', listStyle: 'none', padding: 0 }}>
                    {dots.map((dot, index) => (
                        <li key={index} style={{ margin: '0 5px' }}>
                            <button>{index + 1}</button>
                        </li>
                    ))}
                </ul>
            </div>
        ),
    };

    const goToNext = () => {
        sliderRef.current.slickNext();
    };

    const goToPrev = () => {
        sliderRef.current.slickPrev();
    };

    return (
        <Box sx={{ maxWidth: '1600px', margin: '0 auto', mt: 4 }}>
            {/*<Typography variant="h4" color="primary" align="center" gutterBottom>*/}
            {/*    How It Works: From Start to Finalization*/}
            {/*</Typography>*/}
            <StyledSlider ref={sliderRef} {...settings}>
                {images.map((image, index) => (
                    <Box
                        key={index}
                        sx={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Box
                            onClick={goToPrev}
                            sx={{
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                bottom: 0,
                                width: '50%',
                                cursor: 'pointer',
                            }}
                        />
                        <Box
                            onClick={goToNext}
                            sx={{
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                bottom: 0,
                                width: '50%',
                                cursor: 'pointer',
                            }}
                        />
                        <Box
                            component="img"
                            src={image}
                            alt={`Slide ${index + 1}`}
                            sx={{
                                width: '100%',
                                borderRadius: '10px',
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                            }}
                        />
                    </Box>
                ))}
            </StyledSlider>
        </Box>
    );
};

export default MigrationRegisterSlides;
