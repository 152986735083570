import React, { useState } from "react";
import {
    AppBar,
    Toolbar,
    IconButton,
    Button,
    Box,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Menu,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CloseIcon from "@mui/icons-material/Close";

const navItems = [
    { id: "home", title: "Home", href: "#home-section" },
    { id: "about", title: "About", href: "#about-section" },
    {
        id: "why-workflow",
        title: "Why Workflow",
        href: "#why-workflow-section",
    },
    {
        id: "what-workflow",
        title: "What Workflow",
        subMenu: [
            { title: "What is Workflow", href: "#what-workflow-section" },
            { title: "Roles in the Workflow", href: "#roles-section" },
            { title: "Stages & Notifications", href: "#stages-section" },
        ],
    },
    { id: "faq", title: "FAQ", href: "#faq-section" },
    { id: "setup & deploy", title: "Deploy", href: "#deploy-section" },
    { id: "pricing", title: "Pricing", href: "#pricing-section" },
    { id: "project-timeline", title: "Timeline", href: "#timeline-section" },
    {
        id: "data-insight",
        title: "Data Insight",
        subMenu: [
            { title: "Visa Applications by MARA", href: "#mara-data-section" },
            { title: "Migration Data Insights", href: "#migration-data-section" },
        ],
    },
    {
        id: "resources",
        title: "Resources",
        subMenu: [
            { title: "Resources", href: "#resources-section" },
            { title: "General Immigration References", href: "#general-resources" },
            { title: "OMARA References", href: "#omara-resources" },
            { title: "Skill Assessment", href: "#skill-assessment" },
            { title: "Social References", href: "#social-resources" },
            { title: "Workflow References", href: "#workflow-resources" },
        ],
    },
    { id: "contact", title: "Contact", href: "#contact-section" },
];

const Header = () => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenuId, setOpenMenuId] = useState(null);
    const [isContactOpen, setContactOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleMenuOpen = (event, menuId) => {
        setAnchorEl(event.currentTarget);
        setOpenMenuId(menuId);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setOpenMenuId(null);
    };

    const handleContactOpen = () => {
        setContactOpen(true);
    };

    const handleContactClose = () => {
        setContactOpen(false);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
            {/* Logo in Drawer */}
            <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                <a href="/" aria-label="Migration Register Home">
                    <img
                        src="/assets/logo.png"
                        alt="Migration Register Logo"
                        style={{ width: "150px", height: "auto" }}
                    />
                </a>
            </Box>
            <List>
                {navItems.map((item) =>
                    item.subMenu ? (
                        <List key={item.id}>
                            <ListItem disablePadding>
                                <ListItemText primary={item.title} />
                            </ListItem>
                            {item.subMenu.map((subItem, index) => (
                                <ListItem key={index} disablePadding>
                                    <ListItemText>
                                        <a
                                            href={subItem.href}
                                            style={{
                                                textDecoration: "none",
                                                color: "inherit",
                                                marginLeft: "20px",
                                            }}
                                        >
                                            {subItem.title}
                                        </a>
                                    </ListItemText>
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <ListItem key={item.id} disablePadding>
                            <ListItemText>
                                <a
                                    href={item.href}
                                    style={{ textDecoration: "none", color: "inherit" }}
                                >
                                    {item.title}
                                </a>
                            </ListItemText>
                        </ListItem>
                    )
                )}
            </List>
        </Box>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                position="fixed"
                sx={{
                    // top: 0,
                    backgroundColor: "white",
                    color: "primary.main",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                }}
            >
                <Toolbar>
                    {/* Mobile Menu Icon */}
                    <IconButton
                        color="primary"
                        aria-label="Open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: "none" } }}
                    >
                        <MenuIcon />
                    </IconButton>

                    {/* Logo */}
                    <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                        <a href="/" aria-label="Migration Register Home">
                            <img
                                src="/assets/logo.png"
                                alt="Migration Register Logo"
                                style={{ width: "240px", height: "auto" }}
                            />
                        </a>
                    </Box>

                    {/* Desktop Navigation */}
                    <Box sx={{ display: { xs: "none", sm: "block" } }}>
                        {navItems.map((item) =>
                            item.subMenu ? (
                                <Button
                                    key={item.id}
                                    color="primary"
                                    onClick={(e) => handleMenuOpen(e, item.id)}
                                    sx={{ textTransform: "none", fontWeight: "bold", mx: 1 }}
                                >
                                    {item.title}
                                </Button>
                            ) : (
                                <Button
                                    key={item.id}
                                    color="primary"
                                    href={item.href}
                                    sx={{ textTransform: "none", fontWeight: "bold", mx: 1 }}
                                    onClick={item.id === "contact" ? handleContactOpen : null}
                                >
                                    {item.title}
                                </Button>
                            )
                        )}
                    </Box>
                </Toolbar>
            </AppBar>

            {/* Mobile Drawer */}
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{ keepMounted: true }}
                sx={{
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
                }}
            >
                {drawer}
            </Drawer>

            {/* Submenu Menus */}
            {navItems.map(
                (item) =>
                    item.subMenu && (
                        <Menu
                            key={item.id}
                            anchorEl={anchorEl}
                            open={openMenuId === item.id}
                            onClose={handleMenuClose}
                        >
                            {item.subMenu.map((subItem, index) => (
                                <MenuItem
                                    key={index}
                                    onClick={handleMenuClose}
                                    component="a"
                                    href={subItem.href}
                                >
                                    {subItem.title}
                                </MenuItem>
                            ))}
                        </Menu>
                    )
            )}

            {/* Contact Dialog */}
            <Dialog open={isContactOpen} onClose={handleContactClose} fullWidth maxWidth="xs">
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" color="primary">Contact Us</Typography>
                    <IconButton onClick={handleContactClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ textAlign: 'center', py: 2 }}>
                        {/* Logo */}
                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                            <img
                                src="/assets/logo.png"
                                alt="GetPost Labs Logo"
                                style={{ width: '120px', height: 'auto' }}
                            />
                        </Box>

                        {/* Contact Information */}
                        <Box sx={{ textAlign: 'left', px: 2 }}>
                            <Typography variant="body1" gutterBottom>
                                <strong>Company:</strong> GetPost Labs Pty Ltd
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Address:</strong><br />
                                8 Clunies Ross Ct,<br />
                                Brisbane Technology Park,<br />
                                Queensland 4113
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Contact:</strong> Sumit Arora
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Phone:</strong> +61-432-821-297
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Email:</strong> <a href="mailto:sumit@getpostlabs.io">sumit@getpostlabs.io</a>
                            </Typography>
                        </Box>

                        {/* Actions */}
                        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                href="https://getpostlabs.io/about/contact-us"
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ mb: 2 }}
                            >
                                Visit Contact Page
                            </Button>
                            <IconButton
                                color="primary"
                                href="https://www.linkedin.com/in/sumitarora"
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ ml: 1 }}
                            >
                                <LinkedInIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>


        </Box>
    );
};

export default Header;

