
export const referencesData  = [
        {
            id: 'general-resources',
            section: 'General Immigration References',
            items: [
                { name: 'Home Affairs Org Structure', url: 'https://www.homeaffairs.gov.au/about-us-subsite/files/home-affairs-org-structure.pdf' },
                { name: 'Australia Legislation', url: 'https://www.legislation.gov.au/C1958A00062/asmade/text' },
                { name: 'Visa List', url: 'https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing' },
                { name: 'LEGENDcom-an electronic database of migration and citizenship legislation and policy documents', url: 'https://immi.homeaffairs.gov.au/help-support/tools/legendcom' },
                { name: 'Online Immigration Accounts', url: 'https://online.immi.gov.au/lusc/login' },
                { name: 'The Administration of the Immigration and Citizenship Programs Paper', url: 'https://immi.homeaffairs.gov.au/what-we-do/the-administration-of-the-immigration-program' },
                { name: 'Migration Program planning levels', url: 'https://immi.homeaffairs.gov.au/what-we-do/migration-program-planning-levels' },
                { name: 'Australia\'s 2024–25 permanent Migration Program', url: 'https://www.homeaffairs.gov.au/reports-and-publications/submissions-and-discussion-papers/australias-2024-25-permanent-migration-program' },
                { name: 'Migration Program Statistics', url: 'https://www.homeaffairs.gov.au/research-and-statistics/statistics/visa-statistics/live/migration-program' },
                { name: 'Visa Statistics', url: 'https://www.homeaffairs.gov.au/research-and-statistics/statistics/visa-statistics/visa-statistics' },
                { name: 'Visa Cancellation Statistics', url: 'https://www.homeaffairs.gov.au/research-and-statistics/statistics/visa-statistics/visa-cancellation' },
                { name: 'Australia Migration Trends 2023-2024', url: 'https://www.homeaffairs.gov.au/research-and-stats/files/migration-trends-2023-24.pdf' },
                { name: 'Australia Migration Program Statistics', url: 'https://www.homeaffairs.gov.au/research-and-statistics/statistics/visa-statistics/live/migration-program' },
            ],
        },
        {
            id: 'omara-resources',
            section: 'Office of the Migration Agents Registration Authority (OMARA)',
            items: [
                { name: 'OMARA Official Website', url: 'https://www.mara.gov.au/' },
                { name: 'Search for Registered Migration Agents', url: 'https://portal.mara.gov.au/search-the-register-of-migration-agents/' },
                { name: 'What We Do', url: 'https://www.mara.gov.au/about-us/what-we-do' },
                { name: 'Migration Agent Activity Reports', url: 'https://www.mara.gov.au/notices-and-reports/reports/migration-agent-activity-reports' },
                { name: 'Annual Reports', url: 'https://www.mara.gov.au/notices-and-reports/reports/annual-reports' },
                { name: 'Code of Conduct', url: 'https://www.mara.gov.au/tools-for-registered-agents/code-of-conduct' },
            ],
        },
        {

            id: 'skill-assessment',
            section: 'Skill Assessment Authorities',
            items: [
                { name: 'AACA (Architects Accreditation Council of Australia)', url: 'http://www.aaca.org.au' },
                { name: 'AASW (Australian Association of Social Workers)', url: 'http://www.aasw.asn.au' },
                { name: 'ACECQA', url: 'https://www.acecqa.gov.au/' },
                { name: 'ACS (Australian Computer Society)', url: 'http://www.acs.org.au' },
                { name: 'ANMAC (Nursing & Midwifery Accreditation Council)', url: 'http://www.anmac.org.au' },
                { name: 'Engineers Australia', url: 'http://www.engineersaustralia.org.au' },
                { name: 'NAATI (Translators and Interpreters)', url: 'http://www.naati.com.au' },
                { name: 'VETASSESS (Vocational Education and Training Assessment Services)', url: 'https://www.vetassess.com.au' },
                { name: 'ADC (Australian Dental Council)', url: 'http://www.adc.org.au' },
                { name: 'AIMS (Australian Institute of Medical Scientists)', url: 'http://www.aims.org.au' },
                { name: 'AIQS (Australian Institute of Quantity Surveyors)', url: 'http://www.aiqs.com.au' },
                { name: 'AITSL (Australian Institute for Teaching and School Leadership)', url: 'http://www.aitsl.edu.au' },
                { name: 'AMSA (Australian Maritime Safety Authority)', url: 'http://www.amsa.gov.au' },
                { name: 'ANZPAC (Australian and New Zealand Podiatry Accreditation Council)', url: 'https://iliadint.com/en/article/1205-Australian-and-New-Zealand-Podiatry-Accreditation-Council-ANZPAC' },
                { name: 'ANZSNM (Australian and New Zealand Society of Nuclear Medicine)', url: 'http://www.anzsnm.org.au' },
                { name: 'AOAC (Australasian Osteopathic Accreditation Council)', url: 'http://www.osteopathiccouncil.org.au' },
                { name: 'AOPA (Australian Orthotic Prosthetic Association)', url: 'https://www.aopa.org.au' },
                { name: 'APC (Australian Physiotherapy Council)', url: 'http://www.physiocouncil.com.au' },
                { name: 'APharmC (Australian Pharmacy Council)', url: 'https://www.pharmacycouncil.org.au' },
                { name: 'APS (Australian Psychological Society)', url: 'http://www.psychology.org.au' },
                { name: 'ASMIRT (Australian Society of Medical Imagery and Radiation Therapy)', url: 'http://www.asmirt.org' },
                { name: 'AVBC (Australasian Veterinary Boards Council)', url: 'http://www.avbc.asn.au' },
                { name: 'CAANZ (Chartered Accountants Australia and New Zealand)', url: 'https://www.charteredaccountantsanz.com/' },
                { name: 'CASA (Civil Aviation Safety Authority)', url: 'http://www.casa.gov.au' },
                { name: 'CCEA (Council on Chiropractic Education Australasia)', url: 'http://www.ccea.com.au' },
                { name: 'CMBA (Chinese Medicine Board of Australia)', url: 'http://www.chinesemedicineboard.gov.au' },
                { name: 'CPAA (CPA Australia)', url: 'http://www.cpaaustralia.com.au' },
                { name: 'DAA (Dietitians Association of Australia)', url: 'http://daa.asn.au' },
                { name: 'IML (Institute of Managers and Leaders)', url: 'https://managersandleaders.com.au/' },
                { name: 'IPA (Institute of Public Accountants)', url: 'http://www.publicaccountants.org.au' },
                { name: 'MedBA (Medical Board of Australia)', url: 'http://www.ahpra.gov.au' },
                { name: 'OCANZ (Optometry Council of Australia and New Zealand)', url: 'http://www.ocanz.org' },
                { name: 'OTC (Occupational Therapy Council of Australia)', url: 'http://www.otcouncil.com.au' },
                { name: 'PodBA (Podiatry Board of Australia)', url: 'https://www.podiatryboard.gov.au/' },
                { name: 'SPA (Speech Pathology Australia)', url: 'http://www.speechpathologyaustralia.org.au' },
                { name: 'SSSI (Surveying and Spatial Sciences Institute)', url: 'https://sssi.org.au' },
                { name: 'TRA (Trades Recognition Australia - Trades)', url: 'http://tradesrecognitionaustralia.gov.au' },
                { name: 'TRA (Vocational Education and Training)', url: 'https://www.vetassess.com.au' },
            ],
        },
        {
            id: 'general-resources',
            section: 'Social Media and Online Resources',
            items: [
                { name: 'Home Affairs - Facebook', url: 'https://www.facebook.com/AusHomeAffairs/' },
                { name: 'Home Affairs - LinkedIn', url: 'https://www.linkedin.com/company/australian-department-of-home-affairs' },
                { name: 'Regulatory Reform', url: 'https://www.regulatoryreform.gov.au/priorities/regulator-best-practice-and-performance/regulator-stocktake/department-home-affairs' },
            ],
        },
        {
            id: 'workflow-resources',
            section: 'Workflow References and Resources',
            items: [
                { name: 'Introduction to Workflows - Wikipedia', url: 'https://en.wikipedia.org/wiki/Workflow' },
                { name: 'Business Process Model and Notation (BPMN) - Wikipedia', url: 'https://en.wikipedia.org/wiki/Business_Process_Model_and_Notation' },
                { name: 'Atlassian JIRA: Issue and Project Tracking', url: 'https://www.atlassian.com/software/jira' },
                { name: 'Atlassian Confluence: Collaboration and Documentation', url: 'https://www.atlassian.com/software/confluence' },
                { name: 'Atlassian Tutorials and Guides (YouTube)', url: 'https://www.youtube.com/@Atlassian' },
                { name: 'JIRA Service Management Features', url: 'https://www.atlassian.com/software/jira/service-management/features/service-desk' },
                { name: 'Microsoft Power Automate: Workflow Solutions', url: 'https://learn.microsoft.com/en-us/power-automate/' },
            ],
        },
    ];