import React from 'react';
import {
    Box,
    Container,
    Typography,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import PeopleIcon from '@mui/icons-material/People';
import SchoolIcon from '@mui/icons-material/School';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const DeploymentAndPricing = () => {
    return (
        <Container sx={{py: 10, maxWidth: 'md'}}>
            {/* Deployment & Implementation Approach Section */}
            <section id="deploy-section">
                <Box mb={5}>
                    <Typography variant="h3" color="primary" gutterBottom>
                        Deployment & Implementation Approach
                    </Typography>
                    <Divider sx={{mb: 3}}/>
                    <Typography variant="body1" color="textSecondary" gutterBottom>
                        We establish a detailed Service Contract with every client, outlining project objectives,
                        timelines,
                        and deliverables.During initial weeks, we migrate your existing cases and configure user roles.
                        Over the next few weeks, we gather your team’s feedback, refine the workflow, and provide training
                        so you can fully adopt the system without disruption.
                        <br/>
                        <em>
                            Note: Actual timelines can vary based on the complexity of the project and the number of
                            staff
                            and partners involved. If adjustments are needed, we will work closely to ensure all
                            requirements are met.
                        </em>
                    </Typography>
                    <Box mb={5}>
                        {[{
                            title: 'Initial Assessment & Data Migration',
                            icon: <SettingsIcon sx={{verticalAlign: 'middle', mr: 1}}/>,
                            items: [
                                {
                                    icon: <AssessmentIcon/>,
                                    text: 'We assess your existing cases, staff setup, and partner relationships.'
                                },
                                {
                                    icon: <SyncAltIcon/>,
                                    text: 'We migrate your current visa cases and new cases into the workflow.'
                                },
                            ],
                        }, {
                            title: 'Configuration & Workflow Setup',
                            icon: <SyncAltIcon sx={{verticalAlign: 'middle', mr: 1}}/>,
                            items: [
                                {
                                    icon: <SettingsIcon/>,
                                    text: 'We configure a tailor-made workflow on tools like Jira Work Management.'
                                },
                                {
                                    icon: <PeopleIcon/>,
                                    text: 'We set up roles (Clients, Staff, Partners), statuses, and permissions.'
                                },
                            ],
                        }, {
                            title: 'Partner Access & Integration',
                            icon: <PeopleIcon sx={{verticalAlign: 'middle', mr: 1}}/>,
                            items: [
                                {
                                    icon: <PeopleIcon/>,
                                    text: 'Provide external partner access with tailored visibility.'
                                },
                                {
                                    icon: <SyncAltIcon/>,
                                    text: 'Integrate Microsoft Teams for real-time chat and collaboration.'
                                },
                            ],
                        }, {
                            title: 'Customer Service Integration',
                            icon: <SupportAgentIcon sx={{verticalAlign: 'middle', mr: 1}}/>,
                            items: [
                                {
                                    icon: <SupportAgentIcon/>,
                                    text: 'Connect the workflow with a service desk for ticket management.'
                                },
                                {
                                    icon: <SyncAltIcon/>,
                                    text: 'Enable secure document uploads and status updates for clients.'
                                },
                            ],
                        }, {
                            title: 'Training & Onboarding',
                            icon: <SchoolIcon sx={{verticalAlign: 'middle', mr: 1}}/>,
                            items: [
                                {
                                    icon: <SchoolIcon/>,
                                    text: 'Train staff on using the system for data collection, statuses, and notifications.'
                                },
                                {
                                    icon: <SupportAgentIcon/>,
                                    text: 'Provide knowledge-base documentation for self-sufficiency.'
                                },
                            ],
                        }, {
                            title: 'Support & Continuous Improvement',
                            icon: <SyncAltIcon sx={{verticalAlign: 'middle', mr: 1}}/>,
                            items: [
                                {
                                    icon: <SupportAgentIcon/>,
                                    text: 'Provide ongoing support for updates, refinements, or feature requests.'
                                },
                                {icon: <AssessmentIcon/>, text: 'Track usage and feedback for continuous improvement.'},
                            ],
                        }].map((section, index) => (
                            <Card key={index} sx={{mb: 4, boxShadow: 3}}>
                                <CardContent>
                                    <Typography variant="h6" color="primary" gutterBottom>
                                        {section.icon} {section.title}
                                    </Typography>
                                    <List>
                                        {section.items.map((item, idx) => (
                                            <ListItem key={idx}>
                                                <ListItemIcon>{item.icon}</ListItemIcon>
                                                <ListItemText primary={item.text}/>
                                            </ListItem>
                                        ))}
                                    </List>
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                </Box>
            </section>

                {/* Pricing & Billing Structure Section */}
                <section id="pricing-section">
                    <Box mb={5}>
                        <Typography variant="h3" color="primary" gutterBottom>
                            Pricing & Billing Structure
                        </Typography>
                        <Divider sx={{mb: 3}}/>
                        <Typography variant="body1" color="textSecondary" gutterBottom>
                            In this pricing model, we ensure transparency and accountability while setting up end-to-end
                            workflows for Migration Lawyers and Agents. Feel free to reach out for more details.
                            <br/>
                            <em>
                                Note: Actual timelines can vary based on the complexity of the project and the number of
                                staff
                                and partners involved. If you need more or fewer hours in any given stage, we will
                                adjust
                                accordingly.
                            </em>
                        </Typography>
                        <Box mb={5}>
                            {[{
                                title: 'Initial Setup Phase',
                                icon: <AttachMoneyIcon sx={{verticalAlign: 'middle', mr: 1}}/>,
                                hours: '80 Hours (First Month)',
                                description: 'Billed at 80 x 25 AUD + GST. Covers data migration, workflow configuration, and training.',
                            }, {
                                title: 'Consolidation Phase',
                                icon: <AttachMoneyIcon sx={{verticalAlign: 'middle', mr: 1}}/>,
                                hours: '40 Hours (Second Month)',
                                description: 'Billed at 40 x 25 AUD + GST. Focused on refinements and advanced customizations.',
                            }, {
                                title: 'Advanced Customizations',
                                icon: <AttachMoneyIcon sx={{verticalAlign: 'middle', mr: 1}}/>,
                                hours: '20 Hours (Third Month)',
                                description: 'Billed at 20 x 25 AUD + GST. Covers final touches, stabilization, and optional integrations.',
                            }, {
                                title: 'Ongoing Support',
                                icon: <AttachMoneyIcon sx={{verticalAlign: 'middle', mr: 1}}/>,
                                hours: '10 Hours Per Month',
                                description: 'Billed at 10 x 25 AUD + GST. For continuous support, feature requests, and expansions.',
                            }].map((phase, index) => (
                                <Card key={index} sx={{mb: 4, boxShadow: 3}}>
                                    <CardContent>
                                        <Typography variant="h6" color="primary" gutterBottom>
                                            {phase.icon} {phase.title}
                                        </Typography>
                                        <Typography variant="body1" color="textSecondary">
                                            {phase.hours}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" gutterBottom>
                                            {phase.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            ))}
                        </Box>

                        {/* Additional Pricing Table */}

                        <Box mb={5}>
                            <Typography variant="h6" color="primary" gutterBottom>
                                Example Deployment Cost for an Established Migration Agency
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><strong>Month</strong></TableCell>
                                            <TableCell><strong>Service Description</strong></TableCell>
                                            <TableCell><strong>Cost (AUD)</strong></TableCell>
                                            <TableCell><strong>GST (10%)</strong></TableCell>
                                            <TableCell><strong>Total Cost (AUD)</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Month 1</TableCell>
                                            <TableCell>Setup & Configuration</TableCell>
                                            <TableCell>2,000</TableCell>
                                            <TableCell>200</TableCell>
                                            <TableCell>2,200</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Month 2</TableCell>
                                            <TableCell>Consolidation Phase</TableCell>
                                            <TableCell>1,000</TableCell>
                                            <TableCell>100</TableCell>
                                            <TableCell>1,100</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Month 3</TableCell>
                                            <TableCell>Misc Customizations</TableCell>
                                            <TableCell>500</TableCell>
                                            <TableCell>50</TableCell>
                                            <TableCell>550</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Month 4</TableCell>
                                            <TableCell>Ongoing Support</TableCell>
                                            <TableCell>250</TableCell>
                                            <TableCell>25</TableCell>
                                            <TableCell>275</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Month 5</TableCell>
                                            <TableCell>Ongoing Support</TableCell>
                                            <TableCell>250</TableCell>
                                            <TableCell>25</TableCell>
                                            <TableCell>275</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Month 6</TableCell>
                                            <TableCell>Ongoing Support</TableCell>
                                            <TableCell>250</TableCell>
                                            <TableCell>25</TableCell>
                                            <TableCell>275</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={4}><strong>Total for 6 Months</strong></TableCell>
                                            <TableCell><strong>4,675</strong></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Typography variant="body2" color="textSecondary" mt={2}>
                                Note: This cost is the lowest possible and heavily discounted. Teams typically start
                                using the solution within the first few weeks of deployment.
                            </Typography>
                        </Box>
                    </Box>
                </section>
        </Container>
);
};

export default DeploymentAndPricing;
