import React from 'react';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import About from './components/About/About';
import Footer from './components/Footer/Footer';
import HowItWorks from "./components/HowItWorks/HowItWorks";
import Timeline from "./components/Timeline/Timeline";
import VisaApplicationsData from "./components/VisaApplicationsData/VisaApplicationsData";
import MigrationDataInsights from "./MigrationDataInsights/MigrationDataInsights";
import FAQSlider from "./components/FAQ/FAQSlider";
import ReferencesTable from "./components/References/ReferencesTable";

function App() {
    return (
        <div>
            <Header />
            {/*<Toolbar />*/}
            <Hero />
            <About />
            <HowItWorks />
            {/*/!*<WorkflowDiagram />*!/*/}
            {/*<CustomeNodeFlow />*/}
            <FAQSlider />
            {/*<UserJourney />*/}
            <Timeline />
            {/*<Features />*/}
            <VisaApplicationsData />
            <MigrationDataInsights />
            <ReferencesTable />
            {/*<TeamPage />*/}
            <Footer />
        </div>
    );
}

export default App;
