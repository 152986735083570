// src/components/About/About.js

import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const About = () => {
    return (
        <section id="about-section">
            <Box
                sx={{
                    padding: '10px 0',
                    minHeight: '30vh', // Matches Hero's minHeight
                    display: 'flex',
                    alignItems: 'center', // Centers content vertically
                    justifyContent: 'center', // Centers content horizontally
                    backgroundColor: 'background.default', // Optional: Matches Hero's background
                }}
            >
                <Container maxWidth="md">
                    <Box
                        sx={{
                            border: '2px solid', // Increased border width
                            borderColor: 'grey.500', // Darker border color for prominence
                            borderRadius: 2,
                            padding: 4, // Increased padding for better content spacing
                            backgroundColor: 'background.paper',
                            boxShadow: 3, // Optional: Adds a subtle shadow for depth
                        }}
                    >
                        {/* Heading with Icon */}
                        <Box display="flex" alignItems="center" mb={2}>
                            <InfoIcon color="primary" sx={{mr: 1, fontSize: 30}}/>
                            <Typography variant="h5" component="h2" sx={{fontWeight: 'bold'}}>
                                About Migration Register
                            </Typography>
                        </Box>

                        {/* Body Text */}
                        <Typography variant="body1" paragraph>
                            <strong> Migration Register workflow</strong> is built on Atlassian’s Jira Work Management
                            (for task and workflow management),
                            Jira Service Desk (for customer-facing portals), Confluence (for knowledge base and
                            documentation),
                            and integrates with Microsoft Teams, Outlook, and OneDrive for seamless collaboration.
                            This allows Migration Agents to manage visa cases end-to-end in a secure, centralized
                            environment,
                            providing real-time communication, automated notifications, and robust document handling.
                        </Typography>
                    </Box>
                </Container>
            </Box>
        </section>
            );

            };

            export default About;
