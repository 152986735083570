// components/MigrationDataInsights/MigrationDataInsights.js

import React from 'react';
import { Box, Typography, Container, useMediaQuery } from '@mui/material';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
    CartesianGrid,
    AreaChart,
    Area,
} from 'recharts';
import { migrationData, visaArrivalData } from './data';
import { useTheme } from '@mui/material/styles';

const MigrationDataInsights = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Detects small screens

    // Define label angle based on screen size
    const labelAngle = isSmallScreen ? -90 : -45;

    // Define font size based on screen size
    const labelFontSize = isSmallScreen ? 10 : 12;

    // Define tick interval based on screen size
    const tickInterval = isSmallScreen ? Math.floor(migrationData.length / 5) : 0; // Show ~5 labels on small screens

    // Define tick formatter to shorten date labels
    const formatDate = (date) => {
        // Assuming date format is 'MMM-YY' (e.g., 'Jun-13')
        // Shorten it to 'Jun '13'
        const [month, year] = date.split('-');
        const monthShort = month.slice(0, 3);
        return `${monthShort} '${year}`;
    };

    return (
        <section id="migration-data-section">
        <Box sx={{ py: 10, overflow: 'visible' }}>
            <Container>
                {/* Section Heading */}
                <Typography variant="h3" component="h2" align="center" gutterBottom>
                    Australia Migration Data Insights
                </Typography>

                {/* Key Insights */}
                <Typography variant="body1" align="center" mb={4}>
                    Overseas migration added 518,000 people to Australia's population in the 2022-23 financial year, according to data released by the Australian Bureau of Statistics (ABS).
                </Typography>

                {/* Left Chart */}
                <Box mb={6}>
                    <Typography variant="h6" gutterBottom>
                        Overseas Migration - Australia (Year Ending)
                    </Typography>
                    <Typography variant="body2" color="textSecondary" paragraph>
                        Migrant arrivals, departures, and net overseas migration from 2013 to 2023.
                    </Typography>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart
                            data={migrationData}
                            margin={{ top: 20, right: 30, left:0, bottom: isSmallScreen ? 60 : 0 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="date"
                                angle={labelAngle}
                                textAnchor="end"
                                height={60}
                                interval={tickInterval}
                                tick={{ fontSize: labelFontSize }}
                                tickFormatter={formatDate}
                            />
                            <YAxis
                                label={{
                                    value: 'Number (in thousands)',
                                    angle: -90,
                                    position: 'outsideLeft',
                                    offset: 0,
                                    dy: -20, // Moves the label upward
                                    dx: -20, // Moves the label leftward
                                    style: { fontSize: '12px', fontWeight: 'bold' },
                                }}
                                tickMargin={10}
                                tick={{ fontSize: 12 }}
                            />
                            <Tooltip formatter={(value) => value.toLocaleString()} />
                            <Legend verticalAlign="top" />
                            <Line
                                type="monotone"
                                dataKey="migrantArrivals"
                                name="Migrant Arrivals"
                                stroke="#8884d8"
                                strokeWidth={2}
                            />
                            <Line
                                type="monotone"
                                dataKey="migrantDepartures"
                                name="Migrant Departures"
                                stroke="#82ca9d"
                                strokeWidth={2}
                            />
                            <Line
                                type="monotone"
                                dataKey="netOverseasMigration"
                                name="Net Overseas Migration"
                                stroke="#ff7300"
                                strokeWidth={2}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </Box>

                {/* Right Chart */}
                <Box mb={6}>
                    <Typography variant="h6" gutterBottom>
                        Overseas Migrant Arrivals by Visa and Citizenship Groups (Year Ending)
                    </Typography>
                    <Typography variant="body2" color="textSecondary" paragraph>
                        Migrant arrivals by visa type and citizenship from 2013 to 2023.
                    </Typography>
                    <ResponsiveContainer width="100%" height={300}>
                        <AreaChart
                            data={visaArrivalData}
                            margin={{ top: 20, right: 30, left: 0, bottom: isSmallScreen ? 60 : 0 }}
                        >
                            <defs>
                                <linearGradient id="colorStudent" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="colorOther" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="colorPermanent" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#ffc658" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#ffc658" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                            <XAxis
                                dataKey="date"
                                angle={labelAngle}
                                textAnchor="end"
                                height={60}
                                interval={tickInterval}
                                tick={{ fontSize: labelFontSize }}
                                tickFormatter={formatDate}
                            />
                            <YAxis
                                label={{
                                    value: 'Number (in thousands)',
                                    angle: -90,
                                    position: 'outsideLeft',
                                    offset: 0,
                                    dy: -20, // Moves the label upward
                                    dx: -20, // Moves the label leftward
                                    style: { fontSize: '12px', fontWeight: 'bold' },
                                }}
                                tickMargin={10}
                                tick={{ fontSize: 12 }}
                            />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip formatter={(value) => value.toLocaleString()} />
                            <Legend verticalAlign="top" />
                            <Area
                                type="monotone"
                                dataKey="temporaryStudent"
                                name="Temporary Visas - Student"
                                stroke="#8884d8"
                                fillOpacity={1}
                                fill="url(#colorStudent)"
                            />
                            <Area
                                type="monotone"
                                dataKey="temporaryOther"
                                name="Temporary Visas - Other"
                                stroke="#82ca9d"
                                fillOpacity={1}
                                fill="url(#colorOther)"
                            />
                            <Area
                                type="monotone"
                                dataKey="permanentVisas"
                                name="Permanent Visas"
                                stroke="#ffc658"
                                fillOpacity={1}
                                fill="url(#colorPermanent)"
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                </Box>

                {/* Reference */}
                <Typography variant="body2" color="textSecondary" mt={4}>
                    Source:{' '}
                    <a href="https://www.abs.gov.au/" target="_blank" rel="noopener noreferrer">
                        Australian Bureau of Statistics (ABS)
                    </a>
                </Typography>
            </Container>
        </Box>
        </section>
    );
};

export default MigrationDataInsights;
