// import React from 'react';
// import { Box, Typography, Table, TableBody, TableCell, TableRow, TableContainer, Paper } from '@mui/material';
// import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import styled from '@emotion/styled';
// import DeploymentAndPricing from "./DeploymentAndPricing";
//
// const faqs = [
//     {
//         question: "Can this system be developed using Microsoft tools, such as OneDrive and JIRA Work Management?",
//         answer: "Yes, our solution integrates tools like Jira Work Management for task tracking, OneDrive for secure document storage, Microsoft Teams for collaboration, and Outlook for automated email notifications. We configure these tools to streamline your operations efficiently.",
//     },
//     {
//         question: "Can you provide a session on how this system is organized?",
//         answer: "Absolutely! We offer guided sessions to demonstrate how the tools are configured and used in real-world scenarios, including workflows, task management, and document handling.",
//     },
//     {
//         question: "What benefits can we expect?",
//         answer: "You can expect streamlined operations, improved team collaboration, better compliance tracking, and a fully customized workflow tailored to your specific needs.",
//     },
//     {
//         question: "How does onboarding work?",
//         answer: "Our onboarding involves a structured multi-step approach: First, we assess your current tools and visa cases to plan data migration into our workflow. Next, we configure and customize Jira Work Management, Microsoft Teams, and other tools according to your needs. We then train your staff to use the system effectively, provide in-depth documentation, and conduct follow-up sessions to ensure everything runs smoothly.",
//     },
//     {
//         question: "How do you collect client data?",
//         answer: "We use customized forms and workflows in Jira Service Management to collect client data. The data can be securely stored in OneDrive, and notifications for incomplete or missing information are sent via Outlook.",
//     },
//     {
//         question: "Can you assist with website redevelopment or additional IT services?",
//         answer: "Yes, we provide IT services to enhance your digital operations and integrate them seamlessly with the configured tools.",
//     },
//     {
//         question: "Where are you located?",
//         answer: "Our office is at 8 Clunies Court, Brisbane Technology Park, Brisbane, Queensland.",
//     },
//     {
//         question: "Why did you start solving migration agents’ problems?",
//         answer: "We observed inefficiencies in existing tools and workflows used by migration agents, which inspired us to create tailored solutions for the industry.",
//     },
//     {
//         question: "I need a feature that’s missing. Can you add it?",
//         answer: "Yes, we can develop and integrate additional features into the workflow based on your requirements.",
//     },
//     {
//         question: "How does this solution work overall?",
//         answer: "The solution leverages advanced configurations of Jira and Microsoft tools to create seamless workflows, automate tasks, and centralize communication.",
//     },
//     {
//         question: "Can we integrate this workflow with JIRA Work Management?",
//         answer: "Yes, we specialize in integrating and configuring Jira Work Management to manage tasks, stages, and notifications effectively.",
//     },
//     {
//         question: "Does this solution replace Migration Manager?",
//         answer: "Our solution enhances team workflows and collaboration but does not directly replicate the functionality of Migration Manager.",
//     },
//     {
//         question: "Why don’t you offer e-lodge or auto-filling for ImmiAccount?",
//         answer: "Our focus is on collaboration and compliance. Automation like e-lodging or form filling may be considered in the future based on demand.",
//     },
//     {
//         question: "How will my clients benefit from this tool?",
//         answer: "Clients can securely upload documents, track case progress, and receive automated notifications about updates through the integrated system.",
//     },
//     {
//         question: "What is the pricing structure?",
//         answer: "Our flexible pricing follows an 80-40-20-10 model (hours x rate) over 2–3 months to configure, train, and finalize your workflow. Ultimately, pricing is based on the effort required and the scope of your project.",
//     },
//     {
//         question: "Is the work management solution free?",
//         answer: "Jira Work Management is free for up to 5 users. For additional users, the cost is AUD 7.53 per user.",
//     },
//     {
//         question: "Can the pricing be customized based on our needs?",
//         answer: "Yes, the setup and support fees are calculated based on the specific requirements of your business, ensuring reasonable and transparent pricing for the efforts involved.",
//     },
//     {
//         question: "What does the setup fee include?",
//         answer: "Our setup fee covers initial data migration, workflow configuration, user role assignments, and essential training sessions. This ensures the system is tailored to your specific requirements, including case stages, notifications, and permissions for staff, partners, and clients.",
//     },
//     {
//         question: "What does the support fee cover?",
//         answer: "The monthly support fee covers ongoing maintenance, system updates, minor workflow adjustments, and technical assistance. It also includes help with any new features or refinements you require as your business processes evolve.",
//     },
//
//     // NEW Q&As
//     {
//         question: "How do you migrate my existing cases?",
//         answer: "We start by reviewing your current case records in spreadsheets, emails, or other software. We then import or manually transfer those cases into our system, ensuring all pertinent data, documents, and client information are preserved. Our team works closely with you to maintain data accuracy throughout the migration process.",
//     },
//     {
//         question: "How long does implementation typically take?",
//         answer: "Implementation usually takes 2–3 months for a team of 7–8 employees, depending on complexity and custom requirements. During this period, we configure the workflow, train your staff, set up partner access, and integrate additional tools like Microsoft Teams or Confluence as needed.",
//     },
//     {
//         question: "What is the 80-40-20-10 model for implementation?",
//         answer: "We break down the setup process into four monthly phases, billed according to hours used: 80 hours in the first month, 40 in the second, 20 in the third, and 10 hours per month thereafter. Each phase focuses on specific milestones—like configuration, training, and final refinements—to ensure a structured, efficient rollout of your workflow system.",
//     },
//     {
//         question: "What if we need more or fewer hours in a given month?",
//         answer: "Our pricing model is flexible. If you require additional hours due to complexity or you find you need fewer hours than anticipated, we can adjust the monthly allocation accordingly. We aim to tailor the solution to your actual needs.",
//     },
//     {
//         question: "How do you ensure data security and compliance?",
//         answer: "We leverage secure storage solutions like OneDrive or SharePoint with custom permissions, ensuring only authorized staff, partners, and clients can access case files. We also follow Australian privacy laws and best practices to safeguard sensitive information, including encryption and regular system audits.",
//     },
// ];
//
//
// const StyledTableContainer = styled(TableContainer)`
//   max-width: 1000px;
//   margin: 0 auto;
//   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
//   border-radius: 10px;
// `;
//
// const FAQTable = () => {
//     return (
//         <section id="faq-section">
//             <Box sx={{my: 5, mx: 'auto', maxWidth: '1000px'}}>
//                 <Typography variant="h4" color="primary" align="center" gutterBottom>
//                     Frequently Asked Questions
//                 </Typography>
//                 <Typography>&nbsp;</Typography>
//                 <StyledTableContainer component={Paper}>
//                     <Table aria-label="faq table">
//                         <TableBody>
//                             {faqs.map((faq, index) => (
//                                 <TableRow key={index} sx={{
//                                     '&:hover': {
//                                         backgroundColor: '#f9f9f9',
//                                     },
//                                 }}>
//                                     <TableCell sx={{width: '35%', borderBottom: 'none'}}>
//                                         <Box display="flex" alignItems="center">
//                                             <HelpOutlineIcon color="primary" sx={{mr: 1}}/>
//                                             <Typography variant="h6" component="span">
//                                                 {faq.question}
//                                             </Typography>
//                                         </Box>
//                                     </TableCell>
//                                     <TableCell sx={{width: '65%', borderBottom: 'none'}}>
//                                         <Box display="flex" alignItems="center">
//                                             <CheckCircleOutlineIcon color="success" sx={{mr: 1}}/>
//                                             <Typography variant="body1" component="span">
//                                                 {faq.answer}
//                                             </Typography>
//                                         </Box>
//                                     </TableCell>
//                                 </TableRow>
//                             ))}
//                         </TableBody>
//                     </Table>
//                 </StyledTableContainer>
//                 <DeploymentAndPricing/>;
//             </Box>
//         </section>
//
// );
//
// };
//
// export default FAQTable;
// src/components/FAQ/FAQTable.js

import React from 'react';
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    Paper
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import styled from '@emotion/styled';
import DeploymentAndPricing from "./DeploymentAndPricing";
import faqs from './faqs'; // The updated FAQ array with categories

const StyledTableContainer = styled(TableContainer)`
  max-width: 1000px;
  margin: 0 auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

// Utility function to group FAQs by category
const groupByCategory = (faqArray) => {
    return faqArray.reduce((acc, faqItem) => {
        const { category } = faqItem;
        if (!acc[category]) {
            acc[category] = [];
        }
        acc[category].push(faqItem);
        return acc;
    }, {});
};

const FAQTable = () => {
    // Group FAQs by category
    const groupedFAQs = groupByCategory(faqs);
    // Get a sorted array of category names (so the order is consistent)
    const categories = Object.keys(groupedFAQs).sort();

    return (
        <section id="faq-section">
            <Box sx={{ my: 5, mx: 'auto', maxWidth: '1000px' }}>
                <Typography variant="h4" color="primary" align="center" gutterBottom>
                    Frequently Asked Questions
                </Typography>
                <Typography>&nbsp;</Typography>

                {/* RENDER EACH CATEGORY */}
                {categories.map((category) => (
                    <Box key={category} sx={{ mb: 4 }}>
                        {/* Category Heading */}
                        <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
                            {category}
                        </Typography>

                        <StyledTableContainer component={Paper}>
                            <Table aria-label="faq table">
                                <TableBody>
                                    {groupedFAQs[category].map((faq, index) => (
                                        <TableRow
                                            key={`${category}-${index}`}
                                            sx={{
                                                '&:hover': {
                                                    backgroundColor: '#f9f9f9',
                                                },
                                            }}
                                        >
                                            <TableCell sx={{ width: '35%', borderBottom: 'none' }}>
                                                <Box display="flex" alignItems="center">
                                                    <HelpOutlineIcon color="primary" sx={{ mr: 1 }} />
                                                    <Typography variant="h6" component="span">
                                                        {faq.question}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ width: '65%', borderBottom: 'none' }}>
                                                <Box display="flex" alignItems="center">
                                                    <CheckCircleOutlineIcon color="success" sx={{ mr: 1 }} />
                                                    <Typography variant="body1" component="span">
                                                        {faq.answer}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </StyledTableContainer>
                    </Box>
                ))}

                <DeploymentAndPricing />
            </Box>
        </section>
    );
};

export default FAQTable;
