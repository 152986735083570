// components/MigrationDataInsights/data.js

export const migrationData = [
    { date: 'Jun-14', migrantArrivals: 464.68, migrantDepartures: 276.90, netOverseasMigration: 187.78 },
    { date: 'Sep-14', migrantArrivals: 459.84, migrantDepartures: 276.11, netOverseasMigration: 183.74 },
    { date: 'Dec-14', migrantArrivals: 458.76, migrantDepartures: 276.41, netOverseasMigration: 182.35 },
    { date: 'Mar-15', migrantArrivals: 460.64, migrantDepartures: 279.56, netOverseasMigration: 181.08 },
    { date: 'Jun-15', migrantArrivals: 465.25, migrantDepartures: 281.22, netOverseasMigration: 184.03 },
    { date: 'Sep-15', migrantArrivals: 469.39, migrantDepartures: 285.16, netOverseasMigration: 184.23 },
    { date: 'Dec-15', migrantArrivals: 473.25, migrantDepartures: 286.52, netOverseasMigration: 186.73 },
    { date: 'Mar-16', migrantArrivals: 481.33, migrantDepartures: 285.35, netOverseasMigration: 195.98 },
    { date: 'Jun-16', migrantArrivals: 489.28, migrantDepartures: 283.04, netOverseasMigration: 206.23 },
    { date: 'Sep-16', migrantArrivals: 507.11, migrantDepartures: 277.60, netOverseasMigration: 229.51 },
    { date: 'Dec-16', migrantArrivals: 519.65, migrantDepartures: 275.82, netOverseasMigration: 243.83 },
    { date: 'Mar-17', migrantArrivals: 536.66, migrantDepartures: 277.08, netOverseasMigration: 259.59 },
    { date: 'Jun-17', migrantArrivals: 540.15, migrantDepartures: 276.80, netOverseasMigration: 263.35 },
    { date: 'Sep-17', migrantArrivals: 540.06, migrantDepartures: 280.64, netOverseasMigration: 259.43 },
    { date: 'Dec-17', migrantArrivals: 531.37, migrantDepartures: 289.71, netOverseasMigration: 241.66 },
    { date: 'Mar-18', migrantArrivals: 527.23, migrantDepartures: 289.23, netOverseasMigration: 238.00 },
    { date: 'Jun-18', migrantArrivals: 527.52, migrantDepartures: 289.30, netOverseasMigration: 238.22 },
    { date: 'Sep-18', migrantArrivals: 530.94, migrantDepartures: 288.62, netOverseasMigration: 242.32 },
    { date: 'Dec-18', migrantArrivals: 534.40, migrantDepartures: 282.18, netOverseasMigration: 252.22 },
    { date: 'Mar-19', migrantArrivals: 536.60, migrantDepartures: 285.93, netOverseasMigration: 250.67 },
    { date: 'Jun-19', migrantArrivals: 550.40, migrantDepartures: 309.06, netOverseasMigration: 241.34 },
    { date: 'Sep-19', migrantArrivals: 566.35, migrantDepartures: 324.32, netOverseasMigration: 242.04 },
    { date: 'Dec-19', migrantArrivals: 607.87, migrantDepartures: 360.25, netOverseasMigration: 247.62 },
    { date: 'Mar-20', migrantArrivals: 618.36, migrantDepartures: 379.11, netOverseasMigration: 239.25 },
    { date: 'Jun-20', migrantArrivals: 506.85, migrantDepartures: 314.16, netOverseasMigration: 192.70 },
    { date: 'Sep-20', migrantArrivals: 361.95, migrantDepartures: 286.34, netOverseasMigration: 75.61 },
    { date: 'Dec-20', migrantArrivals: 235.35, migrantDepartures: 240.32, netOverseasMigration: -4.97 },
    { date: 'Mar-21', migrantArrivals: 113.00, migrantDepartures: 207.34, netOverseasMigration: -94.34 },
    { date: 'Jun-21', migrantArrivals: 146.00, migrantDepartures: 230.93, netOverseasMigration: -84.94 },
    { date: 'Sep-21', migrantArrivals: 162.49, migrantDepartures: 218.63, netOverseasMigration: -56.14 },
    { date: 'Dec-21', migrantArrivals: 216.11, migrantDepartures: 209.24, netOverseasMigration: 6.87 },
    { date: 'Mar-22', migrantArrivals: 336.24, migrantDepartures: 208.35, netOverseasMigration: 127.89 },
    { date: 'Jun-22', migrantArrivals: 426.73, migrantDepartures: 223.14, netOverseasMigration: 203.59 },
    { date: 'Sep-22', migrantArrivals: 571.80, migrantDepartures: 229.55, netOverseasMigration: 342.25 },
    { date: 'Dec-22', migrantArrivals: 653.43, migrantDepartures: 220.28, netOverseasMigration: 433.15 },
    { date: 'Mar-23', migrantArrivals: 706.57, migrantDepartures: 214.75, netOverseasMigration: 491.82 },
    { date: 'Jun-23', migrantArrivals: 739.37, migrantDepartures: 203.85, netOverseasMigration: 535.52 },
    { date: 'Sep-23', migrantArrivals: 755.29, migrantDepartures: 199.48, netOverseasMigration: 555.81 },
    { date: 'Dec-23', migrantArrivals: 740.90, migrantDepartures: 205.91, netOverseasMigration: 534.99 },
    { date: 'Mar-24', migrantArrivals: 714.16, migrantDepartures: 211.19, netOverseasMigration: 502.97 },
    { date: 'Jun-24', migrantArrivals: 666.81, migrantDepartures: 221.17, netOverseasMigration: 445.64 },
];

export const visaArrivalData = [
    { date: 'Jun-14', temporaryStudent: 114.33, temporaryOther: 138.83, permanentVisas: 94.35, nzCitizens: 37.77, australianCitizens: 72.18 },
    { date: 'Sep-14', temporaryStudent: 119.01, temporaryOther: 136.86, permanentVisas: 91.26, nzCitizens: 35.62, australianCitizens: 71.65 },
    { date: 'Dec-14', temporaryStudent: 122.49, temporaryOther: 136.03, permanentVisas: 89.29, nzCitizens: 33.78, australianCitizens: 71.30 },
    { date: 'Mar-15', temporaryStudent: 123.53, temporaryOther: 137.40, permanentVisas: 90.23, nzCitizens: 32.57, australianCitizens: 71.18 },
    { date: 'Jun-15', temporaryStudent: 122.13, temporaryOther: 140.80, permanentVisas: 91.49, nzCitizens: 31.75, australianCitizens: 71.68 },
    { date: 'Sep-15', temporaryStudent: 125.04, temporaryOther: 142.27, permanentVisas: 90.67, nzCitizens: 31.28, australianCitizens: 72.03 },
    { date: 'Dec-15', temporaryStudent: 125.98, temporaryOther: 144.67, permanentVisas: 89.75, nzCitizens: 31.47, australianCitizens: 73.12 },
    { date: 'Mar-16', temporaryStudent: 129.84, temporaryOther: 147.69, permanentVisas: 88.97, nzCitizens: 32.26, australianCitizens: 74.23 },
    { date: 'Jun-16', temporaryStudent: 132.13, temporaryOther: 149.63, permanentVisas: 90.59, nzCitizens: 33.70, australianCitizens: 75.80 },
    { date: 'Sep-16', temporaryStudent: 136.59, temporaryOther: 153.57, permanentVisas: 97.17, nzCitizens: 34.88, australianCitizens: 77.85 },
    { date: 'Dec-16', temporaryStudent: 137.87, temporaryOther: 157.90, permanentVisas: 103.73, nzCitizens: 34.86, australianCitizens: 78.67 },
    { date: 'Mar-17', temporaryStudent: 146.81, temporaryOther: 161.96, permanentVisas: 107.41, nzCitizens: 33.79, australianCitizens: 79.64 },
    { date: 'Jun-17', temporaryStudent: 149.41, temporaryOther: 165.41, permanentVisas: 106.20, nzCitizens: 32.33, australianCitizens: 79.28 },
    { date: 'Sep-17', temporaryStudent: 152.65, temporaryOther: 168.58, permanentVisas: 100.85, nzCitizens: 31.16, australianCitizens: 79.20 },
    { date: 'Dec-17', temporaryStudent: 151.94, temporaryOther: 169.17, permanentVisas: 94.47, nzCitizens: 30.39, australianCitizens: 78.81 },
    { date: 'Mar-18', temporaryStudent: 153.79, temporaryOther: 169.17, permanentVisas: 90.08, nzCitizens: 30.37, australianCitizens: 78.01 },
    { date: 'Jun-18', temporaryStudent: 157.63, temporaryOther: 169.68, permanentVisas: 87.91, nzCitizens: 30.38, australianCitizens: 77.16 },
    { date: 'Sep-18', temporaryStudent: 163.24, temporaryOther: 169.50, permanentVisas: 87.10, nzCitizens: 30.57, australianCitizens: 76.50 },
    { date: 'Dec-18', temporaryStudent: 166.64, temporaryOther: 171.27, permanentVisas: 85.09, nzCitizens: 30.84, australianCitizens: 75.99 },
    { date: 'Mar-19', temporaryStudent: 167.55, temporaryOther: 173.96, permanentVisas: 83.85, nzCitizens: 30.57, australianCitizens: 76.02 },
    { date: 'Jun-19', temporaryStudent: 164.34, temporaryOther: 186.33, permanentVisas: 85.39, nzCitizens: 30.54, australianCitizens: 78.90 },
    { date: 'Sep-19', temporaryStudent: 155.54, temporaryOther: 204.99, permanentVisas: 86.99, nzCitizens: 30.36, australianCitizens: 82.84 },
    { date: 'Dec-19', temporaryStudent: 150.65, temporaryOther: 235.20, permanentVisas: 91.24, nzCitizens: 30.41, australianCitizens: 95.02 },
    { date: 'Mar-20', temporaryStudent: 134.53, temporaryOther: 253.08, permanentVisas: 90.84, nzCitizens: 28.73, australianCitizens: 106.04 },
    { date: 'Jun-20', temporaryStudent: 110.44, temporaryOther: 203.21, permanentVisas: 70.89, nzCitizens: 22.22, australianCitizens: 96.41 },
    { date: 'Sep-20', temporaryStudent: 64.87, temporaryOther: 144.46, permanentVisas: 51.45, nzCitizens: 15.90, australianCitizens: 83.39 },
    { date: 'Dec-20', temporaryStudent: 42.57, temporaryOther: 75.71, permanentVisas: 37.35, nzCitizens: 12.03, australianCitizens: 66.66 },
    { date: 'Mar-21', temporaryStudent: 0.78, temporaryOther: 19.04, permanentVisas: 27.25, nzCitizens: 11.15, australianCitizens: 54.10 },
    { date: 'Jun-21', temporaryStudent: 1.51, temporaryOther: 28.06, permanentVisas: 36.95, nzCitizens: 16.95, australianCitizens: 61.37 },
    { date: 'Sep-21', temporaryStudent: 2.08, temporaryOther: 34.91, permanentVisas: 43.18, nzCitizens: 18.99, australianCitizens: 61.09 },
    { date: 'Dec-21', temporaryStudent: 29.21, temporaryOther: 48.13, permanentVisas: 55.51, nzCitizens: 19.16, australianCitizens: 61.57 },
    { date: 'Mar-22', temporaryStudent: 102.72, temporaryOther: 79.71, permanentVisas: 66.04, nzCitizens: 21.96, australianCitizens: 63.03 },
    { date: 'Jun-22', temporaryStudent: 141.42, temporaryOther: 124.79, permanentVisas: 71.10, nzCitizens: 24.12, australianCitizens: 62.48 },
    { date: 'Sep-22', temporaryStudent: 215.03, temporaryOther: 178.82, permanentVisas: 78.57, nzCitizens: 30.01, australianCitizens: 66.85 },
    { date: 'Dec-22', temporaryStudent: 237.66, temporaryOther: 234.72, permanentVisas: 77.04, nzCitizens: 35.85, australianCitizens: 66.09 },
    { date: 'Mar-23', temporaryStudent: 259.95, temporaryOther: 266.48, permanentVisas: 76.50, nzCitizens: 40.22, australianCitizens: 61.84 },
    { date: 'Jun-23', temporaryStudent: 277.76, temporaryOther: 278.86, permanentVisas: 80.39, nzCitizens: 42.66, australianCitizens: 58.75 },
    { date: 'Sep-23', temporaryStudent: 284.47, temporaryOther: 284.11, permanentVisas: 83.71, nzCitizens: 44.93, australianCitizens: 58.07 },
    { date: 'Dec-23', temporaryStudent: 267.15, temporaryOther: 279.74, permanentVisas: 86.93, nzCitizens: 47.83, australianCitizens: 59.26 },
    { date: 'Mar-24', temporaryStudent: 240.67, temporaryOther: 272.58, permanentVisas: 90.66, nzCitizens: 50.24, australianCitizens: 60.00 },
    { date: 'Jun-24', temporaryStudent: 206.77, temporaryOther: 258.01, permanentVisas: 90.88, nzCitizens: 51.10, australianCitizens: 60.04 },
];
