const faqs = [
    {
        category: "Migration Register Workflow",
        question: "We do not want all the stages of our visa cases and may not need all the statuses of various cases. Is that possible?",
        answer: "The workflow provided here is a general template designed to cover most stages and statuses. However, the actual workflow is customized to suit your specific requirements and ensure maximum efficiency."
    },
    {
        category: "Migration Register Workflow",
        question: "Why do we need your help? Can't we do it ourselves?",
        answer: "Yes, you can set it up yourself. This page provides most of the information you need. If you require minimal support or guidance, we can offer free sessions to help you get started independently."
    },
    {
        category: "Migration Register Workflow",
        question: "Can we onboard our customers through this system and manage all documentation via this system?",
        answer: "Yes, you can onboard your customers and manage documentation through this system. We typically customize the workflow using Atlassian's Service Desk solution to suit your specific needs."
    },
    {
        category: "Migration Register Workflow",
        question: "Why does it take a few months to implement the workflow?",
        answer: "Typically, we set up initial stages, statuses, documentation in Confluence, roles, and rules within 2-3 weeks. We also analyze your existing user management and workflows to create a tailor-made solution. After implementation, we gather feedback, make adjustments, and review the workflow weekly. Additionally, training and support are provided to ensure your team can use the system effectively."
    },
    {
        category: "Migration Register Workflow",
        question: "Can we avail services for only the first month?",
        answer: "Yes, it is absolutely possible. We can provide services for the first month and deliver the necessary setup and support."
    },
    {
        category: "Migration Register Workflow",
        question: "What solutions do you use to develop the workflow?",
        answer: "We implement workflows using Atlassian's web-based collaboration tools, such as JIRA Work Management, Confluence, and JIRA Service Desk. Additionally, we integrate tools like Microsoft Teams and email to streamline the process. For more details, refer to https://www.atlassian.com/."
    },
    // ----- Tools & Integrations -----
    {
        category: "Tools & Integrations",
        question: "Can this system be developed using Microsoft tools, such as OneDrive and JIRA Work Management?",
        answer: "Yes, our solution integrates tools like Jira Work Management for task tracking, OneDrive for secure document storage, Microsoft Teams for collaboration, and Outlook for automated email notifications. We configure these tools to streamline your operations efficiently.",
    },
    {
        category: "Tools & Integrations",
        question: "Can we integrate this workflow with JIRA Work Management?",
        answer: "Yes, we specialize in integrating and configuring Jira Work Management to manage tasks, stages, and notifications effectively.",
    },
    {
        category: "Tools & Integrations",
        question: "Does this solution replace Migration Manager?",
        answer: "Our solution enhances team workflows, communication, and collaboration. It’s not intended to directly replicate all features of Migration Manager but can integrate with or replace many aspects of your existing process.",
    },
    {
        category: "Tools & Integrations",
        question: "Why don’t you offer e-lodge or auto-filling for ImmiAccount?",
        answer: "Our focus is on collaboration and compliance. Automation like e-lodging or form filling may be considered in the future based on demand.",
    },
    {
        category: "Tools & Integrations",
        question: "Can you assist with website redevelopment or additional IT services?",
        answer: "Yes, we provide IT services to enhance your digital operations and integrate them seamlessly with the configured tools.",
    },

    // ----- Onboarding & Implementation -----
    {
        category: "Onboarding & Implementation",
        question: "Can you provide a session on how this system is organized?",
        answer: "Absolutely! We offer guided sessions to demonstrate how the tools are configured and used in real-world scenarios, including workflows, task management, and document handling.",
    },
    {
        category: "Onboarding & Implementation",
        question: "How does onboarding work?",
        answer: "Our onboarding involves a structured multi-step approach: First, we assess your current tools and visa cases to plan data migration into our workflow. Next, we configure and customize Jira Work Management, Microsoft Teams, and other tools according to your needs. We then train your staff to use the system effectively, provide in-depth documentation, and conduct follow-up sessions to ensure everything runs smoothly.",
    },
    {
        category: "Onboarding & Implementation",
        question: "How do you collect client data?",
        answer: "We use customized forms and workflows in Jira Service Management to collect client data. The data can be securely stored in OneDrive, and notifications for incomplete or missing information are sent via Outlook.",
    },
    {
        category: "Onboarding & Implementation",
        question: "How do you migrate my existing cases?",
        answer: "We start by reviewing your current case records in spreadsheets, emails, or other software. We then import or manually transfer those cases into our system, ensuring all pertinent data, documents, and client information are preserved. Our team works closely with you to maintain data accuracy throughout the migration process.",
    },
    {
        category: "Onboarding & Implementation",
        question: "How long does implementation typically take?",
        answer: "Implementation usually takes 2–3 months for a team of 7–8 employees, depending on complexity and custom requirements. During this period, we configure the workflow, train your staff, set up partner access, and integrate additional tools like Microsoft Teams or Confluence as needed.",
    },
    {
        category: "Onboarding & Implementation",
        question: "What is the 80-40-20-10 model for implementation?",
        answer: "We break down the setup process into four monthly phases, billed according to hours used: 80 hours in the first month, 40 in the second, 20 in the third, and 10 hours per month thereafter. Each phase focuses on specific milestones—like configuration, training, and final refinements—to ensure a structured, efficient rollout of your workflow system.",
    },
    {
        category: "Onboarding & Implementation",
        question: "What if we need more or fewer hours in a given month?",
        answer: "Our pricing model is flexible. If you require additional hours due to complexity or you find you need fewer hours than anticipated, we can adjust the monthly allocation accordingly. We aim to tailor the solution to your actual needs.",
    },

    // ----- Pricing & Billing -----
    {
        category: "Pricing & Billing",
        question: "What is the pricing structure?",
        answer: "Our flexible pricing follows an 80-40-20-10 model (hours x rate) over 2–3 months to configure, train, and finalize your workflow. Ultimately, pricing is based on the effort required and the scope of your project.",
    },
    {
        category: "Pricing & Billing",
        question: "Is the work management solution free?",
        answer: "Jira Work Management is free for up to 5 users. For additional users, the cost is AUD 7.53 per user.",
    },
    {
        category: "Pricing & Billing",
        question: "Can the pricing be customized based on our needs?",
        answer: "Yes, the setup and support fees are calculated based on the specific requirements of your business, ensuring reasonable and transparent pricing for the efforts involved.",
    },
    {
        category: "Pricing & Billing",
        question: "What does the setup fee include?",
        answer: "Our setup fee covers initial data migration, workflow configuration, user role assignments, and essential training sessions. This ensures the system is tailored to your specific requirements, including case stages, notifications, and permissions for staff, partners, and clients.",
    },
    {
        category: "Pricing & Billing",
        question: "What does the support fee cover?",
        answer: "The monthly support fee covers ongoing maintenance, system updates, minor workflow adjustments, and technical assistance. It also includes help with any new features or refinements you require as your business processes evolve.",
    },

    // ----- Data Security & Compliance -----
    {
        category: "Data Security & Compliance",
        question: "How do you ensure data security and compliance?",
        answer: "We follow best practices to safeguard sensitive information, including encryption and regular system audits.",
    },

    // ----- General / Misc -----
    {
        category: "General",
        question: "What benefits can we expect?",
        answer: "You can expect streamlined operations, improved team collaboration, better compliance tracking, and a fully customized workflow tailored to your specific needs.",
    },
    {
        category: "General",
        question: "Where are you located?",
        answer: "Our office is at 8 Clunies Court, Brisbane Technology Park, Brisbane, Queensland.",
    },
    {
        category: "General",
        question: "Why did you start solving migration agents’ problems?",
        answer: "We observed inefficiencies in existing tools and workflows used by migration agents, which inspired us to create tailored solutions for the industry.",
    },
    {
        category: "General",
        question: "I need a feature that’s missing. Can you add it?",
        answer: "Yes, we can develop and integrate additional features into the workflow based on your requirements.",
    },
    {
        category: "General",
        question: "How does this solution work overall?",
        answer: "The solution leverages advanced configurations of Jira and Microsoft tools to create seamless workflows, automate tasks, and centralize communication.",
    },
    {
        category: "General",
        question: "How will my clients benefit from this tool?",
        answer: "Clients can securely upload documents, track case progress, and receive automated notifications about updates through the integrated system.",
    },
];

export default faqs;
